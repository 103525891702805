import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Accordion, Form, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import PlansBanner from '../../DashboardComponents/plansBanner';
import Cookies from 'universal-cookie';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { updateGetToSectionAlgolia, updateSendToNameSection, updateSendToNameSubSection, updateShowContentInDocs } from '../../../redux/slices/KnowDivInScreen';
import "./style.css"
import logoWhite from '../../../assets/img/logo_white.png';
import logo1 from '../../../assets/img/logo1.png';

//algolia
// import { InstantSearch, SearchBox, Hits, HitsPerPage } from 'react-instantsearch-dom';
import searchClient from "../../../algoliaConfig"
import CustomSearchBox from '../../CustomSearchBox/CustomSearchBox';
import {
  Configure,
  Highlight,
  Hits,
  Index,
  InstantSearch,
  SearchBox,
  connectStateResults
} from 'react-instantsearch-dom';

//Modal
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CustomHit from '../../CustomHit/CustomHit';

//img
import logo from '../../../assets/img/avap_logo.png';

import "../../../assets/css/baseLayout.css"
import { gray } from 'd3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';


const cookies = new Cookies();

// ESTE ES EL SIDEBAR DE LOS DOCUMENTOS

type SideProps = {
  sider: any,
  active?: string,
  cambio?: () => void
};

const ApiSideBar: FunctionComponent<SideProps> = ({
  sider = [],
  active = "api-products",
  cambio,
}: SideProps) => {

  const [yaLink, setyaLink] = useState(false);
  const sendToNameSection = useSelector((state: any) => state.nameDiv.sendToNameSection)
  let nameDiv = active.includes('-') && !yaLink ? active : useSelector((state: any) => state.nameDiv.name)
  const dispatch = useDispatch()

  const [isLoggedIn, setLogged] = useState(false);
  const [navMenu, setNav] = useState([]);
  const [expanded, setExpanded] = useState('');
  const { menus } = sider;
  let { basePath } = sider;
  //To use search algolia
  const indexAlgolia = basePath ? basePath.replace("/", "") : "";

  // Estados para el sidebar móvil
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState('');
  const [submenuContent, setSubmenuContent] = useState([]);
  const [currentSubmenuTitle, setCurrentSubmenuTitle] = useState('');
  const sidebarRef = useRef(null);

  const history = useHistory();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const routerLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(e.currentTarget.pathname);
    cambio();
  }

  const [divSelected, setDivSelected] = useState(null);

  const [isChecked, setIsChecked] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
  };

  // Función para abrir el sidebar móvil
  const openMobileSidebar = () => {
    setMobileSidebarOpen(true);
    document.body.style.overflow = 'hidden'; // Prevenir scroll del body
  };

  // Función para cerrar el sidebar móvil
  const closeMobileSidebar = () => {
    setMobileSidebarOpen(false);
    setActiveSubmenu('');
    document.body.style.overflow = ''; // Restaurar scroll del body
  };

  // Función para abrir un submenú
  const openSubmenu = (id, title) => {
    setActiveSubmenu(id);
    setCurrentSubmenuTitle(title);

    // Encontrar el menú correspondiente y preparar su contenido
    const menuItem = menus.find(menu => menu.id === id);
    if (menuItem && menuItem.links) {
      setSubmenuContent(menuItem.links);
    }
  };

  // Función para volver al menú principal
  const backToMainMenu = () => {
    setActiveSubmenu('');
  };

  // Manejar clic en un elemento del submenú
  const handleSubmenuItemClick = (divId) => {
    getAndSetNameSubSection(divId);
    closeMobileSidebar();
  };

  // Escuchar clics fuera del sidebar para cerrarlo
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) &&
        !event.target.closest('.dp-mobile-menu-button')) {
        closeMobileSidebar();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const checkForActivedSupport = async () => {
    try {

      if (!isLoggedIn) {
        const supportScript = document.getElementById('ze-snippet') as HTMLScriptElement;

        if (!supportScript) { // si no existe el script
          const script = document.createElement('script');

          script.id = 'ze-snippet';

          const user = cookies.get('101Obex');

          if (!user) {
            script.src = 'https://static.zdassets.com/ekr/snippet.js?key=ff86f044-4911-46f6-91fc-fd90fa14cd08'
          }
          script.async = true;

          document.body.appendChild(script);
        }
      }

    } catch (error) {
      console.error('ERROR GETTING ACTIVED SUPPORT ', error);
    }
  }

  useEffect(() => {
    // Solo establecer si active no está definido
    if (!active) {
      setExpanded("api-products");
    }
  }, [active]);



  // Nueva función para renderizar contenido dinámico basado en `active`
  const renderContent = () => {
    console.log("Active section:", active);
    console.log("Sider data:", sider);

    if (active === "api-products") {
      return (
        <div className="api-products-section">
          {sider.map((api) => (
            <Nav key={api.id} className="flex-column mb-2">
              <Nav.Link href={api.swagger_link} target="_blank">
                {api.name}
              </Nav.Link>
            </Nav>
          ))}
        </div>
      );
    }

    if (active === "docs") {
      return (
        <div className="docs-section">
          {sider.map((api) => (
            <div key={api.id} className="mb-4">
              <h5>{api.name}</h5>
              {api.documentation_links.map((doc, index) => (
                <Nav.Link key={index} href={doc.url} target="_blank">
                  {doc.title}
                </Nav.Link>
              ))}
            </div>
          ))}
        </div>
      );
    }

    return; //<div>No matching section</div>;
  };



  const loadNav = () => {
    const { menus = [], basePath = "" } = sider || {};
    const navs = menus.map((nav) => {
      // debugger
      const { id, links, icon, title } = nav;

      let ident = nav.id;
      if (ident == undefined) ident = Math.random();
      // debugger
      return (
        <Nav key={'apisidebar' + nav.id} className="flex-column mb-2">
          <div className={`px-0 title-nav d-flex justify-content-between ${expanded == id ? 'link-active' : ''}`}>
            {/* {nav.icon &&
              <img className={'pr-2'} src={`/src/assets/img/iconosv2/icon-${icon}.png`} />
            } */}
            <div className='hover_name_section' onClick={() => getAndSetNameSection(id)} >{title}</div>
            <div>
              {expanded == id ? (

                <div>
                  {/* <svg role="img" aria-hidden="false" aria-label="Dropdown open" width={"12px"} height={"12px"} viewBox="0 0 16 16" fill="#6c7688" xmlns="http://www.w3.org/2000/svg" ><path fill-rule="evenodd" clip-rule="evenodd" d="M.381 4.381a.875.875 0 0 1 1.238 0L8 10.763l6.381-6.382A.875.875 0 1 1 15.62 5.62l-7 7a.875.875 0 0 1-1.238 0l-7-7a.875.875 0 0 1 0-1.238Z"></path></svg>*/}
                </div>

              ) : (
                <div>
                  {/* <svg width={"8px"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg> */}
                  {/*<svg role="img" aria-hidden="false" aria-label="Dropdown closed" width={"12px"} height={"12px"} viewBox="0 0 16 16" fill="#6c7688" xmlns="http://www.w3.org/2000/svg" ><path fill-rule="evenodd" clip-rule="evenodd" d="M4.381.381a.875.875 0 0 0 0 1.238L10.763 8 4.38 14.381a.875.875 0 1 0 1.24 1.239l7-7a.875.875 0 0 0 0-1.238l-7-7a.875.875 0 0 0-1.238 0Z"></path></svg>*/}
                </div>


              )

              }

            </div>
          </div>
          {expanded === id && (
            <div>

              <Nav.Item className="ml-2">
                {links.map(link => {
                  const { title, path } = link;
                  const divId = `${id}-${path}`;
                  console.log("Construyendo divId:", { id, path, divId });
                  const partes = nameDiv ? nameDiv.split('-') : "";
                  let itemSelected = partes[1] === path ? true : false;

                  // console.log("partes", partes);
                  // console.log("path", path);


                  return <Nav.Link
                    key={'linapisidebar' + link.path}
                    className={`pl-2 ${itemSelected ? "item_selected rounded" : ""} `}
                    // href={url} 
                    onClick={() => getAndSetNameSubSection(divId)}
                  >
                    <span className={` ${itemSelected ? "item_selected_color" : ""} text-capitalize`}>{title}</span>
                  </Nav.Link>;
                })}
              </Nav.Item>
            </div>
          )}

        </Nav>
      )
    })
    setNav(navs);

  }

  const getAndSetNameSection = (id: string) => {
    console.log(id);
    if (expanded === id) setExpanded(''); else setExpanded(id);

    // dispagiteSection(id));

  };

  window.onload = function () {

    console.log("TERMINADO ")

    // Perform actions after the window has fully loaded

    setTimeout(function () {
      //console.log(nameDiv)
      //nameDiv 
      //setyaLink(true);
    }, 3000);


  };

  const getAndSetNameSubSection = (divId: string) => {
    // console.log('Subsection selected:', divId);
    dispatch(updateShowContentInDocs(true)); //when is view default
    dispatch(updateSendToNameSubSection(divId));

    // setExpanded(id)
    dispatch(updateSendToNameSubSection(divId));
    console.log("===== INFORMACIÓN DEL DIVID =====");
    console.log("divId completo:", divId);
    if (divId.includes('-')) {
      const [seccion, ruta] = divId.split('-');
      console.log("Sección (id):", seccion);
      console.log("Ruta (path):", ruta);
    }
    console.log("================================");
  };

  useEffect(() => {
    //
    console.log("SIDER");
    console.log(sider);
    console.log("ññññññ")

    console.log(`ACTIVE ${active}`);


    setExpanded(active);
    loadNav()
    const user = cookies.get('101Obex');
    if (user) {
      setLogged(true);
    } else {
      setLogged(false);
    }
    loadNav();
    // const defaultActive = menus[0].id;
    // setExpanded(defaultActive);
    checkForActivedSupport();
    console.log("------.------")
    console.log(nameDiv)
    console.log("------.------")
  }, []);

  useEffect(() => {
    loadNav();

  }, [expanded]);

  //Check when value change
  useEffect(() => {
    let partes = nameDiv ? nameDiv.split('-') : "";

    if (partes.length > 0) {
      console.log("if expanded", partes);

      setExpanded(partes[0]);
      loadNav();
    } else {
      //setExpanded('');
      partes = [active.split('-')[0], active.split('-')[1]]
      console.log(`ACTIVEEEEEE ${active}`)
      console.log(partes);
      setExpanded(active.split('-')[0]); // AQUI ES
      nameDiv = active;

      loadNav();

      //setyaLink(true);
      // loadNav();
    }


    if (active != 'default' && active != 'developer' && active != '') getAndSetNameSubSection(active);
  }, [nameDiv]);

  const handleClick = (hit) => {
    //To send sections when use ALGOLIA
    // console.log(`Item clicked: ${hit.name_section + "-" + hit.path}`);
    let sectionAlgolia = hit.name_section.toLowerCase() + "-" + hit.path;
    dispatch(updateGetToSectionAlgolia(sectionAlgolia));
    handleClose();
  };

  const ProductHit = ({ hit, isSelected, onClick, onMouseEnter, onMouseLeave }) => (
    <div
      className={`rounded py-2 px-2 searchItem ${isSelected ? 'selected' : ''}`}
      onClick={() => onClick(hit)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        backgroundColor: isSelected ? '#61AA8B' : '#fff',
        color: isSelected ? '#fff' : '#353a44'
      }}
    >
      <div className='d-flex justify-content-between'>
        <div>
          <span className='text-bold'>{hit.title}</span> &mdash; <span className='text-capitalize'>{hit.name_section.toLowerCase()}
          </span>
        </div>
        <div>
          <span><svg aria-hidden="true" width={"12px"} height={"12px"} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style={{ fill: isSelected ? '#fff' : '#353a44' }}><path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.75C0 1.784.784 1 1.75 1H5.5c1.005 0 1.904.456 2.5 1.173A3.243 3.243 0 0 1 10.5 1h3.75c.966 0 1.75.784 1.75 1.75v10.5A1.75 1.75 0 0 1 14.25 15H1.75A1.75 1.75 0 0 1 0 13.25V2.75ZM7.25 13.5V4.25A1.75 1.75 0 0 0 5.5 2.5H1.75a.25.25 0 0 0-.25.25v10.5c0 .138.112.25.25.25h5.5Zm1.5 0h5.5a.25.25 0 0 0 .25-.25V2.75a.25.25 0 0 0-.25-.25h-1.754v3.75a.75.75 0 0 1-1.5 0V2.5H10.5a1.75 1.75 0 0 0-1.75 1.75v9.25Z"></path></svg></span>
        </div>
      </div>


    </div>
  );
  // Componente para mostrar el resultado personalizado según el estado de búsqueda
  const CustomStateResults = connectStateResults(({ searchState, searchResults }) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const dispatch = useDispatch();

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (!searchResults || searchResults.nbHits === 0) return;

        if (event.key === 'ArrowDown') {
          setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, searchResults.hits.length - 1));
        } else if (event.key === 'ArrowUp') {
          setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (event.key === 'Enter' && selectedIndex >= 0) {
          handleClick(searchResults.hits[selectedIndex]);
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [selectedIndex, searchResults]);

    const handleClick = (hit) => {
      dispatch(updateShowContentInDocs(true));//when is view default
      let sectionAlgolia = hit.name_section.toLowerCase() + "-" + hit.path;
      dispatch(updateGetToSectionAlgolia(sectionAlgolia));
      handleClose();
    };

    if (!searchResults || searchResults.nbHits === 0) {
      return <NoResults searchState={searchState} />;
    } else {
      return searchResults.hits.map((hit, index) => (
        <ProductHit
          key={hit.objectID}
          hit={hit}
          isSelected={index === selectedIndex}
          onClick={handleClick}
          onMouseEnter={() => setSelectedIndex(index)}
          onMouseLeave={() => setSelectedIndex(-1)}
        />
      ));
    }
  });

  // Componente para mostrar el mensaje cuando no hay resultados
  const NoResults = connectStateResults(({ searchState, searchResults }) => {
    if (searchResults && searchResults.nbHits === 0) {
      // return <div>No se encontraron resultados para "{searchState.query}"</div>;
      return <div>No results</div>;
    }
    return null;
  });

  // Renderizar el sidebar móvil
  const renderMobileSidebar = () => {
    return (
      <>
        <div className={`mobile-sidebar-overlay ${mobileSidebarOpen ? 'active' : ''}`} onClick={closeMobileSidebar}></div>
        <div className={`mobile-sidebar ${mobileSidebarOpen ? 'active' : ''}`} ref={sidebarRef}>
          <div className="mobile-sidebar-header">
            {activeSubmenu ? (
              <div className="mobile-sidebar-back" onClick={backToMainMenu}>
                <FontAwesomeIcon icon={faArrowLeft} className="chevron-left" />
                <span>Volver</span>
              </div>
            ) : (
              <div className="mobile-sidebar-logo">
                {/*  <img src={currentTheme === 'dark' ? logoWhite : logo1} alt="Logo" style={{ height: '30px' }} /> */}
                <span>Sub Menu</span>
              </div>
            )}
            <div className="mobile-sidebar-close" onClick={closeMobileSidebar}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>

          <div className="mobile-sidebar-content">
            {activeSubmenu ? (
              // Contenido del submenú
              <div className="mobile-submenu-content">
                <h5 className="mb-3">{currentSubmenuTitle}</h5>
                {submenuContent.map((link) => {
                  const divId = `${activeSubmenu}-${link.path}`;
                  return (
                    <div
                      key={`mobile-${divId}`}
                      className="mobile-menu-item"
                      onClick={() => handleSubmenuItemClick(divId)}
                    >
                      <span>{link.title}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              // Menú principal
              <>
                {menus && menus.map((menu) => (
                  <div
                    key={`mobile-menu-${menu.id}`}
                    className="mobile-menu-item"
                    onClick={() => openSubmenu(menu.id, menu.title)}
                  >
                    <span>{menu.title}</span>
                    <FontAwesomeIcon icon={faChevronRight} className="chevron-right" />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* Sidebar móvil */}
      {renderMobileSidebar()}

      {/* Botón para abrir el sidebar en móvil */}
      <div id='mobile-menu-sidebar' className="d-md-none d-flex justify-content-between align-items-center p-2">
        <span>
          Sub Menu
        </span>
        <button className="dp-mobile-menu-button " onClick={openMobileSidebar} >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      <div className='position-sticky mt-5' style={{}}>
        <div className="mt-2 d-none d-md-flex flex-column fixed-top-section" style={{ paddingLeft: '35px' }} >
          <div className='pt-3 pb-2 d-flex justify-content-start' style={{ width: '289px' }}><img src={logo} alt="" className='logoBar' /></div>

          <div id='searchWrapper' className=' backgroundFind border d-flex rounded p-1 mb-2 customPointer' onClick={handleShow} style={{ position: 'relative', marginTop: '10px' }}>
            <div className='ml-1 mr-2' style={{ width: '11px' }} > {/* Establece un ancho fijo para el icono */}
              <svg className="icono-svg lupe" width={"100%"} fill="#6c7688" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
              </svg>
            </div>
            <div className='textRegular'>Find anything</div>
          </div>
        </div>
        <div id="sidebar" className="mt-2 d-none d-md-flex flex-column" style={{ overflow: 'scroll', paddingLeft: '35px', width: '289px' }}>


          <Accordion defaultActiveKey={active || ''} className="mt-2">
            {navMenu}
            {renderContent()}
            {false && <PlansBanner />}
          </Accordion>


          <Modal show={show} onHide={handleClose} id="themodal">
            <Modal.Body >
              <InstantSearch
                indexName={indexAlgolia}
                searchClient={searchClient}
                insights={true}
              >
                <CustomSearchBox />
                <Index indexName={indexAlgolia}>
                  <Configure hitsPerPage={6} />
                  <CustomStateResults />
                </Index>

              </InstantSearch>
            </Modal.Body>
            <Modal.Footer className='p-1' id='modalFooter'>
              <div className='d-flex justify-content-between' style={{ width: "100%" }}>
                <div className={`d-flex align-items-center mr-2 content ${isHovered ? 'fade-out' : 'fade-in d-flex'}`}>
                  <div className='d-flex mr-2 align-items-baseline'>
                    <svg aria-hidden="true" width={"11px"} height={"11px"} className="mr-1" viewBox="0 0 16 16" fill="#6c7688" xmlns="http://www.w3.org/2000/svg" ><path fill-rule="evenodd" clip-rule="evenodd" d="M2.35 9.915a.875.875 0 0 1 1.235-.065L8 13.823l4.415-3.973a.875.875 0 0 1 1.17 1.3l-5 4.5a.873.873 0 0 1-1.17 0l-5-4.5a.875.875 0 0 1-.065-1.235ZM7.415.35a.873.873 0 0 1 1.17 0l5 4.5a.875.875 0 1 1-1.17 1.3L8 2.177 3.585 6.15a.875.875 0 0 1-1.17-1.3l5-4.5Z"></path></svg>
                    <div className='fontFooterSearch'>Navigate</div>
                  </div>
                  <div className='d-flex mr-1 align-items-baseline' style={{ transform: 'scaleY(-1)' }}>
                    <svg aria-hidden="true" width={"11px"} height={"11px"} viewBox="0 0 16 16" fill="#6c7688" xmlns="http://www.w3.org/2000/svg"><path d="M5.994 2.38a.875.875 0 1 0-1.238-1.238l-4.25 4.25A.849.849 0 0 0 .25 6c0 .232.093.466.257.63l4.25 4.24a.875.875 0 1 0 1.236-1.24L3.238 6.875h7.387C12.492 6.875 14 8.271 14 10c0 1.797-1.578 3.375-3.375 3.375a.875.875 0 0 0 0 1.75c2.763 0 5.125-2.362 5.125-5.125 0-2.83-2.43-4.872-5.12-4.875H3.24l2.754-2.746Z"></path></svg>
                  </div>
                  <div className='fontFooterSearch'>Go</div>
                </div>
                {isHovered && <div className='fontFooterSearch'>
                  Press <span className='mr-1 border rounded ctrlF'>Ctrl-F</span> again to use native browser search.
                </div>}



                <div className='d-flex align-items-center cursor-pointer'
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className='mr-1 fontFooterSearch text-bold'>Open on</div>
                  <div className='mr-1 border rounded ctrlF'>Ctrl-F</div>
                  <div>
                    <Form.Check
                      type="checkbox"
                      id="exampleCheckbox"
                      checked={isChecked}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default ApiSideBar;
